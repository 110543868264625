<template>
    <div class="asset-tabs-component">
        <template v-for="tab in tabs">
            <div :class="['item',active === tab.key?'active':'']" :key="tab.key" @click="gotoPage(tab)">
                {{tab.name}}
            </div>
        </template>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import ToolAlign from '@/components/icon/ToolAlign';
import ToolTermExtraction from '@/components/icon/ToolTermExtraction';
import ToolQa from '@/components/icon/ToolQa';
import ToolDetector from '@/components/icon/ToolDetector';
export default {
    name:'AssetTabs',
    components:{ ToolAlign, ToolTermExtraction, ToolQa, ToolDetector },
    props:{
        active:{
            type:String,
            default:'term',
        }
    },
    data(){
        return {
            tabs:[
                // {name:'语料对齐',key:'align',path:'/tools/align/index'},
                // {name:'术语提取',key:'termExtraction',path:'/tools/termExtraction/index'},
                // {name:'QA检查',key:'qa',path:'/tools/qa/index'},
                // {name:'机翻检测器',key:'detector',path:'/tools/detector/index'},
            ]
        }
    },
    computed:{
       /*  ...mapState({
            userInfo: state => state.user.userData,
        }), */
        ...mapGetters({
            userInfo: 'user/userInfo',
        })
    },
    created(){
        let tabs = [];
        tabs.push({name:'语料对齐',key:'align',path:'/tools/align/index'});
        if (['pro-internal', 'internal', 'enterprise', 'STANDARD', 'TRIAL', 'ULTIMATE'].indexOf(this.userInfo.accountType) > -1){
            tabs.push({name:'术语提取',key:'termExtraction',path:'/tools/termExtraction/index'});
            tabs.push({name:'QA检查',key:'qa',path:'/tools/qa/index'});
            tabs.push({name:'机翻检测器',key:'detector',path:'/tools/detector/index'});
        }
        if(['pro-trial', 'pro'].indexOf(this.userInfo.accountType) > -1){
            tabs.push({name:'机翻检测器',key:'detector',path:'/tools/detector/index'});
        }
        this.tabs = tabs;
    },
    methods:{
        gotoPage(tab){
            if(this.active !== tab.key){
                this.$router.push(tab.path);
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.asset-tabs-component{
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 15px;
    display: flex;
    > .item{
        color: #2C2C2E;
        font-size: 15px;
        box-sizing: border-box;
        padding: 10px 25px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        &.active{
            color: #00D959;
            background-color: #2C2C2E; 
        }
    }
}
</style>
