<template>
    <svg :width="size" :height="size + 2" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3C3 1.34315 4.34315 0 6 0H17C18.6569 0 20 1.34315 20 3V16C20 17.6569 18.6569 19 17 19H6C4.34315 19 3 17.6569 3 16V3Z" fill="#498EF6"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.06199 4.83522C8.08113 5.1107 8.3205 5.33402 8.59664 5.33402H16.8612C17.1373 5.33402 17.3457 5.1107 17.3265 4.83522C17.3074 4.55975 17.068 4.33643 16.7919 4.33643H8.52734C8.2512 4.33643 8.04286 4.55975 8.06199 4.83522Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.06199 6.68483C8.04286 6.40935 8.2512 6.18604 8.52734 6.18604H17.5872C17.8634 6.18604 18.1028 6.40935 18.1219 6.68483C18.141 6.96031 17.9327 7.18363 17.6565 7.18363H8.59664C8.3205 7.18363 8.08113 6.96031 8.06199 6.68483Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.06199 8.57546C8.08113 8.85094 8.3205 9.07426 8.59664 9.07426H14.7224C14.9985 9.07426 15.2069 8.85094 15.1878 8.57546C15.1686 8.29998 14.9292 8.07666 14.6531 8.07666H8.52734C8.2512 8.07666 8.04286 8.29998 8.06199 8.57546Z" fill="white"/>
        <path d="M0 6C0 4.34315 1.34315 3 3 3H14C15.6569 3 17 4.34315 17 6V19C17 20.6569 15.6569 22 14 22H3C1.34315 22 0 20.6569 0 19V6Z" fill="#67A7F9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.96531 7.50124C2.98445 7.77672 3.22382 8.00004 3.49996 8.00004H11.7645C12.0406 8.00004 12.249 7.77672 12.2299 7.50124C12.2107 7.22576 11.9713 7.00244 11.6952 7.00244H3.43066C3.15452 7.00244 2.94618 7.22576 2.96531 7.50124Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.96531 9.35085C2.98445 9.62633 3.22382 9.84965 3.49996 9.84965H12.5599C12.836 9.84965 13.0443 9.62633 13.0252 9.35085C13.0061 9.07537 12.7667 8.85205 12.4906 8.85205H3.43066C3.15452 8.85205 2.94618 9.07537 2.96531 9.35085Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.96531 11.2415C2.98445 11.517 3.22382 11.7403 3.49996 11.7403H9.62572C9.90187 11.7403 10.1102 11.517 10.0911 11.2415C10.0719 10.966 9.83257 10.7427 9.55643 10.7427H3.43066C3.15452 10.7427 2.94618 10.966 2.96531 11.2415Z" fill="white"/>
    </svg>


</template>
<script>
export default {
    name: 'ToolAlign',
    props: {
        size: {
            type: Number,
            default: 20,
        },
    },
};
</script>
