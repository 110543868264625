<template>
    <svg :width="size" :height="size - 4" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.07109 0.498171C9.46804 -0.166057 10.4604 -0.166057 10.8574 0.498171L19.7887 15.4433C20.1857 16.1075 19.6895 16.9378 18.8956 16.9378H1.03285C0.238945 16.9378 -0.257243 16.1075 0.139708 15.4433L9.07109 0.498171Z" fill="#1AC49C"/>
        <path d="M12.5588 3.5C12.9771 2.83333 14.0229 2.83333 14.4412 3.5L23.8528 18.5C24.2711 19.1667 23.7482 20 22.9116 20H4.08838C3.25179 20 2.72893 19.1667 3.14722 18.5L12.5588 3.5Z" fill="#7DD6B3"/>
        <path d="M13.6253 9.20322C13.4254 8.93226 13.0203 8.93226 12.8205 9.20323L10.0985 12.894C9.85504 13.2241 10.0907 13.6907 10.5009 13.6907H12.0961V16.6087C12.0961 17.161 12.5438 17.6087 13.0961 17.6087H13.352C13.9043 17.6087 14.352 17.161 14.352 16.6087V13.6907H15.9448C16.355 13.6907 16.5907 13.2241 16.3472 12.894L13.6253 9.20322Z" fill="white"/>
    </svg>


</template>
<script>
export default {
    name: 'ToolTermExtraction',
    props: {
        size: {
            type: Number,
            default: 24,
        },
    },
};
</script>
