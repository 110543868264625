<template>
    <svg :width="size" :height="size + 2" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="17" height="20" rx="3" fill="#827BFB"/>
        <g filter="url(#filter0_b_1217_19161)">
            <rect x="3" y="3" width="17" height="19" rx="3" fill="#9394EF"/>
        </g>
        <path d="M16.7881 16.7698L14.584 14.581C15.1845 13.788 15.543 12.8044 15.543 11.7366C15.543 9.12631 13.4017 7 10.7699 7C8.13813 7 6 9.12631 6 11.7366C6 14.3469 8.14128 16.4732 10.7699 16.4732C11.8044 16.4732 12.7603 16.1453 13.5432 15.5864L15.76 17.7877C15.9015 17.9282 16.087 18 16.2725 18C16.458 18 16.6435 17.9282 16.785 17.7877C17.0711 17.5067 17.0711 17.0508 16.7881 16.7698ZM7.45267 11.7366C7.45267 9.91939 8.94308 8.4394 10.7731 8.4394C12.6031 8.4394 14.0935 9.91939 14.0935 11.7366C14.0935 13.5538 12.6031 15.0338 10.7731 15.0338C8.93994 15.0338 7.45267 13.5569 7.45267 11.7366Z" fill="white"/>
        <defs>
            <filter id="filter0_b_1217_19161" x="-27" y="-27" width="77" height="79" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImage" stdDeviation="15"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1217_19161"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1217_19161" result="shape"/>
            </filter>
        </defs>
    </svg>


</template>
<script>
export default {
    name: 'ToolDetector',
    props: {
        size: {
            type: Number,
            default: 20,
        },
    },
};
</script>
