<template>
    <svg :width="size" :height="size - 8" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1217_19165)">
        <path d="M10.9914 0.0600586C5.56776 0.0600586 1.16938 4.29355 1.16938 9.51384C1.16938 10.6748 1.3886 11.7896 1.78867 12.8189C2.0347 13.452 2.04258 14.167 1.71177 14.7601L0.112276 17.628C-0.275504 18.3234 0.404439 19.121 1.18531 18.896L4.98135 17.7982C5.51458 17.644 6.08339 17.7279 6.58286 17.9701C7.90824 18.6127 9.40351 18.9727 10.9914 18.9727C16.415 18.9727 20.8134 14.7392 20.8134 9.51895C20.8134 4.29355 16.415 0.0600586 10.9914 0.0600586Z" fill="#FF9191"/>
        <path d="M17.5 20C23.299 20 28 15.5228 28 10C28 4.47715 23.299 0 17.5 0C11.701 0 7 4.47715 7 10C7 15.5228 11.701 20 17.5 20Z" fill="#FEADA8"/>
        <g filter="url(#filter0_f_1217_19165)">
        <path d="M16.711 12.7381L18.6802 14.2312L17.8421 14.9335L15.9089 13.4551L16.711 12.7381ZM18.6956 9.9188V10.3117C18.6956 10.852 18.6219 11.3366 18.4745 11.7656C18.3271 12.1945 18.1163 12.5596 17.8421 12.8608C17.5679 13.1621 17.2405 13.3929 16.8601 13.5534C16.4796 13.7105 16.058 13.7891 15.5953 13.7891C15.1359 13.7891 14.7161 13.7105 14.3356 13.5534C13.9585 13.3929 13.6312 13.1621 13.3535 12.8608C13.0759 12.5596 12.86 12.1945 12.7057 11.7656C12.5549 11.3366 12.4795 10.852 12.4795 10.3117V9.9188C12.4795 9.37853 12.5549 8.89556 12.7057 8.46989C12.8565 8.04094 13.0691 7.67585 13.3433 7.37461C13.6209 7.07009 13.9483 6.83924 14.3253 6.68207C14.7058 6.52163 15.1257 6.44141 15.585 6.44141C16.0477 6.44141 16.4693 6.52163 16.8498 6.68207C17.2337 6.83924 17.5627 7.07009 17.837 7.37461C18.1112 7.67585 18.322 8.04094 18.4694 8.46989C18.6202 8.89556 18.6956 9.37853 18.6956 9.9188ZM17.3999 10.3117V9.90898C17.3999 9.50951 17.3588 9.15751 17.2765 8.85299C17.1977 8.5452 17.0794 8.28816 16.9218 8.08187C16.7675 7.87231 16.5773 7.71514 16.3511 7.61036C16.1283 7.50231 15.8729 7.44828 15.585 7.44828C15.3039 7.44828 15.052 7.50231 14.8292 7.61036C14.6064 7.71514 14.4161 7.87231 14.2585 8.08187C14.1008 8.28816 13.9808 8.5452 13.8986 8.85299C13.8163 9.15751 13.7752 9.50951 13.7752 9.90898V10.3117C13.7752 10.7112 13.8163 11.0648 13.8986 11.3726C13.9808 11.6804 14.1008 11.9407 14.2585 12.1536C14.4196 12.3631 14.6115 12.5219 14.8343 12.63C15.0605 12.7348 15.3142 12.7872 15.5953 12.7872C15.8832 12.7872 16.1385 12.7348 16.3613 12.63C16.5841 12.5219 16.7727 12.3631 16.9269 12.1536C17.0811 11.9407 17.1977 11.6804 17.2765 11.3726C17.3588 11.0648 17.3999 10.7112 17.3999 10.3117Z" fill="#FF7D7D"/>
        <path d="M22.9219 7.49248L20.6854 13.6909H19.3331L22.1507 6.53964H23.0145L22.9219 7.49248ZM24.7934 13.6909L22.5517 7.49248L22.454 6.53964H23.3229L26.1508 13.6909H24.7934ZM24.6855 11.0386V12.016H20.6134V11.0386H24.6855Z" fill="#FF7D7D"/>
        </g>
        <path d="M14.6427 12.9318L16.8033 14.3383L15.8838 15L13.7627 13.6073L14.6427 12.9318ZM16.8202 10.2759V10.646C16.8202 11.155 16.7394 11.6115 16.5777 12.0156C16.4159 12.4197 16.1847 12.7636 15.8838 13.0474C15.5829 13.3312 15.2238 13.5487 14.8063 13.6998C14.3889 13.8479 13.9263 13.9219 13.4186 13.9219C12.9146 13.9219 12.4539 13.8479 12.0365 13.6998C11.6228 13.5487 11.2636 13.3312 10.959 13.0474C10.6544 12.7636 10.4175 12.4197 10.2482 12.0156C10.0827 11.6115 10 11.155 10 10.646V10.2759C10 9.76692 10.0827 9.31193 10.2482 8.91093C10.4137 8.50684 10.6469 8.16291 10.9477 7.87912C11.2524 7.59225 11.6115 7.37478 12.0252 7.22672C12.4426 7.07557 12.9033 7 13.4073 7C13.915 7 14.3776 7.07557 14.795 7.22672C15.2162 7.37478 15.5773 7.59225 15.8781 7.87912C16.179 8.16291 16.4103 8.50684 16.572 8.91093C16.7375 9.31193 16.8202 9.76692 16.8202 10.2759ZM15.3986 10.646V10.2666C15.3986 9.8903 15.3535 9.5587 15.2633 9.27183C15.1768 8.98188 15.047 8.73973 14.874 8.5454C14.7048 8.34799 14.4961 8.19992 14.2478 8.10121C14.0034 7.99942 13.7232 7.94853 13.4073 7.94853C13.0989 7.94853 12.8225 7.99942 12.578 8.10121C12.3336 8.19992 12.1249 8.34799 11.9519 8.5454C11.7789 8.73973 11.6472 8.98188 11.557 9.27183C11.4667 9.5587 11.4216 9.8903 11.4216 10.2666V10.646C11.4216 11.0224 11.4667 11.3555 11.557 11.6455C11.6472 11.9354 11.7789 12.1806 11.9519 12.3811C12.1286 12.5786 12.3392 12.7282 12.5837 12.83C12.8319 12.9287 13.1102 12.978 13.4186 12.978C13.7345 12.978 14.0147 12.9287 14.2591 12.83C14.5036 12.7282 14.7104 12.5786 14.8797 12.3811C15.0489 12.1806 15.1768 11.9354 15.2633 11.6455C15.3535 11.3555 15.3986 11.0224 15.3986 10.646Z" fill="white"/>
        <path d="M21.4573 7.99017L19.0034 13.8294H17.5197L20.6111 7.09254H21.5589L21.4573 7.99017ZM23.5107 13.8294L21.0511 7.99017L20.944 7.09254H21.8973L25 13.8294H23.5107ZM23.3923 11.3308V12.2516H18.9244V11.3308H23.3923Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_f_1217_19165" x="-7.52051" y="-13.5586" width="53.6709" height="48.4922" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_1217_19165"/>
        </filter>
        <clipPath id="clip0_1217_19165">
        <rect width="28" height="20" fill="white"/>
        </clipPath>
        </defs>
    </svg>


</template>
<script>
export default {
    name: 'ToolQa',
    props: {
        size: {
            type: Number,
            default: 28,
        },
    },
};
</script>
